import React from 'react';
import ReactMarkdown from 'react-markdown';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import Layout from '../components/layout';
import Section from '../components/section';
import PriceListTable from '../components/price-list-table';

export default function ServiceTemplate({ data }) {
  const pageInfo = {
    id: 'service-page',
    url: `/services/${data.strapiService.slug}`,
    title: data.strapiService.title,
    description: data.strapiService.description,
    keywords: data.strapiService.keywords,
    image: data.strapiService.cover.publicURL,
    breadcrumbs: [
      {
        title: 'Главная',
        link: '/',
      },
      {
        title: 'Услуги и цены',
        link: '/services',
      },
      {
        title: data.strapiService.title,
        link: null,
      },
    ],
  };

  return (
    <Layout pageInfo={pageInfo}>
      <Section>
        <article>
          <GatsbyImage
            image={data.strapiService.cover.childImageSharp.gatsbyImageData}
            alt={data.strapiService.title}
          />
          <ReactMarkdown>
            {data.strapiService.article}
          </ReactMarkdown>
          {
            data.strapiService.video
              ? (
                <React.Fragment>
                  <h2>
                    Посмотрите наше видео
                  </h2>
                  <div className="iframe-container">
                    <iframe
                      title={data.strapiService.title}
                      src={data.strapiService.video}
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen=""
                    />
                  </div>
                </React.Fragment>
              )
              : null
          }
        </article>
      </Section>
      <Section colorScheme="dark">
        <h2 style={{ color: '#ffffff' }}>
          Цены на услуги
        </h2>
        <PriceListTable
          priceList={
            data.strapiService.prices
              .sort((a, b) => {
                if (a.title > b.title) return 1;
                if (a.title < b.title) return -1;
                return 0;
              })
          }
        />
      </Section>
    </Layout>
  );
}

export const query = graphql`
  query($strapiId: Int!) {
    strapiService(strapiId: { eq: $strapiId }) {
      strapiId
      slug
      title
      description
      keywords
      weight
      cover {
        publicURL
        childImageSharp {
          gatsbyImageData
        }
      }
      article
      video
      prices {
        title
        price
      }
    }
  }
`;
