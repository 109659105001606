import React from 'react';

import './price-list-table.css';

export default function PriceListTable({ priceList }) {
  return (
    <div className="price-list-table-container">
      <table className="price-list-table">
        <thead className="price-list-table__head">
          <tr>
            <th>Наименование</th>
            <th>Цена</th>
          </tr>
        </thead>
        <tbody className="price-list-table__body">
          {
            priceList.map((service) => (
              <tr>
                <td>{service.title}</td>
                <td>{service.price}</td>
              </tr>
            ))
          }
        </tbody>
      </table>
    </div>
  );
}
